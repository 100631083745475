import { Component, Inject, Input, OnInit } from '@angular/core';
import { MenuItemType } from '../initial-menus-state';
import { rendersMenuItemForType } from '../menu-item.decorator';
import { isNotEmpty } from '../../empty.util';
import {ExternalMenuItemModel} from './models/external.model';

/**
 * Component that renders a menu section of type EXTERNAL
 */
@Component({
  selector: 'ds-external-menu-item',
  templateUrl: './external-menu-item.component.html'
})
@rendersMenuItemForType(MenuItemType.EXTERNAL)
export class ExternalMenuItemComponent implements OnInit {
  item: ExternalMenuItemModel;
  hasLink: boolean;
  constructor(
    @Inject('itemModelProvider') item: ExternalMenuItemModel,
  ) {
    this.item = item;
  }

  ngOnInit(): void {
    this.hasLink = isNotEmpty(this.item.link);
  }

  getRouterLink() {
    if (this.hasLink) {
      return this.item.link;
    }
    return undefined;
  }
}
