/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, SimpleChanges } from '@angular/core';
import { PaginationComponentOptions } from '../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../../app/core/cache/models/sort-options.model';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { of as observableOf } from 'rxjs/internal/observable/of';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PaginationService } from '../../../app/core/pagination/pagination.service';
import { fadeIn } from '../../../app/shared/animations/fade';
import { AbstractObjectCollectionWrapperComponent } from '../base/abstract-object-collection-wrapper.component';
import { ListableObjectSourceFactoryService } from '../sources/listable-object-source-factory.service';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';

const TRACK_CHANGES_TO = [
  'sourceModel',
  'initalPaginationOptions',
  'initalSortOptions',
];

@Component({
  selector: 'ds-atmire-pagination',
  templateUrl: './atmire-pagination.component.html',
  styleUrls: ['./atmire-pagination.component.scss'],
  animations: [fadeIn],
})
/**
 * Shows fully paginated object collections.
 */
export class AtmirePaginationComponent<T extends DSpaceObject> extends AbstractObjectCollectionWrapperComponent<T> {
  /**
   * The initial pagination of the component. These can be changed after the component is initialized.
   */
  @Input() initialPaginationOptions: PaginationComponentOptions;

  /**
   * The initial sort options of the component. These can be changed after the component is initialized.
   */
  @Input() initialSortOptions: SortOptions;

  /**
   * Option for hiding the gear
   */
  @Input() hideGear: boolean;

  private _resultsRD$: BehaviorSubject<RemoteData<PaginatedList<ListableObject>>>;
  results$: Observable<ListableObject[]>;

  constructor(
    protected translateService: TranslateService,
    protected paginationService: PaginationService,
    protected sourceFactory: ListableObjectSourceFactoryService,
  ) {
    super(sourceFactory, translateService);
  }

  getResults(): void {
    this.isLoading$.next(true);
    this.error$ = observableOf(undefined);

    const RD$ = combineLatest([
      this.paginationService.getCurrentPagination(this.initialPaginationOptions.id, this.initialPaginationOptions),
      this.paginationService.getCurrentSort(this.initialPaginationOptions.id, this.initialSortOptions),
    ]).pipe(
      switchMap(([paginationOptions, sortOptions]) => this.source.getList(paginationOptions, sortOptions))
    );

    this.subs.push(
      RD$.subscribe((RD: RemoteData<PaginatedList<ListableObject>>) => {
        if (RD.errorMessage) {
          this.setError('atmire.object-collection.error.backend');
        }
        this.resultsRD$.next(RD);
        this.isLoading$.next(RD.isLoading);
        this.pageChange.emit(RD);
      })
    );
  }

  public get resultsRD$() {
    if (this._resultsRD$ === undefined) {
      this._resultsRD$ = new BehaviorSubject(undefined);
      this.results$ = this._resultsRD$.pipe(
        map((rd) => rd?.payload?.page),
      );
    }
    return this._resultsRD$;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (Object.keys(changes).some(k => TRACK_CHANGES_TO.includes(k))) {
      this.getResults();
    }
  }
}
