<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="statlet-btn w-100" *ngVar="(statletsShown$ | async) as shown">
  <div class="mb-4" *ngIf="hasStatlets$ | async">
    <button *ngIf="shown" (click)="hideStatlets()" class="btn btn-outline-primary mr-2">
      {{"statlets.hide" | translate}}
    </button>
    <button *ngIf="!shown" (click)="showStatlets()" class="btn btn-outline-primary mr-2">
      {{"statlets.show" | translate}}
    </button>
  </div>
  <ng-container *ngIf="shown">
    <ng-container *ngVar="(statletsRD$ | async) as statletRD">
      <div class="row" *ngIf="hasData$ && (hasData$ | async)">
        <ng-container *ngVar="statletRD?.payload?.page as statlets">
          <ng-container *ngFor="let statlet of statlets">
            <div *ngIf="statlet.graphtype" [class.d-none]="!singleStatlet.hasData" [class.mb-4]="!isInlineContext"
                 [ngClass]="!isInlineContext ? (statlet.style.width ? statlet.style.width.classes : statlet.graphtype.defaultWidth.classes) : undefined">
              <ds-atmire-cua-single-statlet-wrapper-loader #singleStatlet [statlet]="statlet" [context]="context"></ds-atmire-cua-single-statlet-wrapper-loader>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="statletRD?.isLoading" class="col-12">
        <ds-loading [message]="'statlets.loading' | translate"></ds-loading>
      </div>
      <ds-alert *ngIf="hasData$ && !(hasData$ | async) && statletRD?.hasSucceeded" [content]="'statlets.no-data' | translate" [type]="AlertTypeEnum.Warning"></ds-alert>
      <ds-alert *ngIf="statletRD?.hasFailed" [content]="'statlets.error' | translate" [type]="AlertTypeEnum.Error"></ds-alert>
    </ng-container>
  </ng-container>
</div>
