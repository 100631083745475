/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PaginationComponentOptions } from '../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../../app/core/cache/models/sort-options.model';
import { Observable } from 'rxjs/internal/Observable';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { FindListOptions } from '../../../app/core/data/request.models';
import { RESTURLCombiner } from '../../../app/core/url-combiner/rest-url-combiner';
import { HrefOnlyDataService } from '../../../app/core/data/href-only-data.service';
import { RemoteData } from '../../../app/core/data/remote-data';
import { AtmireHrefListableObjectSourceModel } from '../listable-object-sources';
import { AbstractListableObjectSource } from './abstract-listable-object-source';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';

/**
 * Retrieves objects via {@link HrefOnlyDataService}.
 */
export class AtmireHrefListableObjectSource<T extends DSpaceObject> extends AbstractListableObjectSource<T> {
  private readonly href: string;

  constructor(
    model: AtmireHrefListableObjectSourceModel<T>,
    private hrefOnlyDataService: HrefOnlyDataService,
  ) {
    super(model);
    this.href = model.href;
  }

  public getList(
    paginationOptions: Partial<PaginationComponentOptions>, sort: Partial<SortOptions>
  ): Observable<RemoteData<PaginatedList<ListableObject>>> {
    return this.hrefOnlyDataService.findAllByHref<T>(
      this.normalizedHref,
      Object.assign(new FindListOptions(), {
        sort: sort,
        currentPage: paginationOptions.currentPage,
        elementsPerPage: paginationOptions.pageSize,
      }),
      this.useCachedVersionIfAvailable,
      this.reRequestOnStale,
      ...this.linksToFollow
    );
  }

  private get normalizedHref(): string {
    return new RESTURLCombiner(...this.href.split('/')).toString();
  }

  public get params(): object {
    return { href: this.href };
  }
}
