/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { columnsFromArray } from '../../../app/shared/utils/grid.util';
import { HostWindowService } from '../../../app/shared/host-window.service';
import { AlertType } from '../../../app/shared/alert/aletr-type';
import { ViewMode } from '../../../app/core/shared/view-mode.model';
import { Context } from '../../../app/core/shared/context.model';
import { CollectionElementLinkType } from '../../../app/shared/object-collection/collection-element-link.type';
import { fadeIn } from '../../../app/shared/animations/fade';

@Component({
  selector: 'ds-atmire-object-collection',
  templateUrl: 'atmire-object-collection.component.html',
  styleUrls: ['./atmire-object-collection.component.scss'],
  animations: [
    fadeIn,
  ],
})
/**
 * Displays an array of ListableObjects in a list or grid view.
 */
export class AtmireObjectCollectionComponent implements OnChanges {
  /**
   * The objects to display
   */
  @Input() objects$: Observable<ListableObject[]>;

  /**
   * The error message to show.
   */
  @Input() error$: Observable<string>;

  /**
   * Whether the object collection is currently loading
   */
  @Input() isLoading$: Observable<boolean>;

  /**
   * The view mode to use for the objects.
   */
  @Input() viewMode: ViewMode;

  /**
   * The context of the objects.
   */
  @Input() context: Context;

  /**
   * The link type to use for the objects.
   */
  @Input() linkType: CollectionElementLinkType;

  /**
   * The i18n message to show in case the object array is empty.
   * If error$ emits an error message, this message will not be shown.
   */
  @Input() noResultsMsg;

  objectsInColumns$: Observable<ListableObject[][]>;

  public AlertType = AlertType;
  public ViewMode = ViewMode;

  constructor(
    protected hostWindow: HostWindowService,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('objects$')) {
      // arrange the objects into columns for grid view
      this.objectsInColumns$ = columnsFromArray(this.objects$, this.hostWindow.widthCategory);
    }
  }
}
