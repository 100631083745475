/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../app/shared/shared.module';
import { StatletDataService } from './data/services/statlet-data.service';
import { StatletsSectionComponent } from './statlets-section/statlets-section.component';
import { SingleStatletCardComponent } from './single-statlet/single-statlet-card/single-statlet-card.component';
import { SingleStatletPieComponent } from './single-statlet/graph-types/single-statlet-pie/single-statlet-pie.component';
import { SingleStatletDirective } from './single-statlet/graph-types/single-statlet.directive';
import { SingleStatletLineHorizontalComponent } from './single-statlet/graph-types/single-statlet-line-horizontal/single-statlet-line-horizontal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MergedSingleStatletComponent } from './single-statlet/graph-types/merged-single-statlet/merged-single-statlet.component';
import { SingleStatletBarHorizontalComponent } from './single-statlet/graph-types/single-statlet-bar-horizontal/single-statlet-bar-horizontal.component';
import { DynamicSingleStatletComponent } from './single-statlet/graph-types/dynamic-single-statlet.component';
import { SingleStatletMapComponent } from './single-statlet/graph-types/single-statlet-map/single-statlet-map.component';
import { AmchartsModule } from '../../../../app/amcharts/amcharts.module';
import { SingleStatletNumberComponent } from './single-statlet/graph-types/single-statlet-number/single-statlet-number.component';
import { SingleStatletBarVerticalComponent } from './single-statlet/graph-types/single-statlet-bar-vertical/single-statlet-bar-vertical.component';
import { SingleStatletTableComponent } from './single-statlet/graph-types/single-statlet-table/single-statlet-table.component';
import { SingleStatletGraphLoaderComponent } from './single-statlet/single-statlet-graph-loader/single-statlet-graph-loader.component';
import { StatletDefaultDataTypeService } from './single-statlet/data-type-services/statlet-default-data-type.service';
import { StatletDsoCommunityDataTypeService } from './single-statlet/data-type-services/statlet-dso-community-data-type.service';
import { StatletDsoCollectionDataTypeService } from './single-statlet/data-type-services/statlet-dso-collection-data-type.service';
import { StatletDsoItemDataTypeService } from './single-statlet/data-type-services/statlet-dso-item-data-type.service';
import { StatletCountryCodeDataTypeService } from './single-statlet/data-type-services/statlet-country-code-data-type.service';
import { StatletSubjectDataTypeService } from './single-statlet/data-type-services/statlet-subject-data-type.service';
import { SingleStatletGaugeComponent } from './single-statlet/graph-types/single-statlet-gauge/single-statlet-gauge.component';
import { SingleStatletAreaComponent } from './single-statlet/graph-types/single-statlet-area/single-statlet-area.component';
import { SingleStatletBarHorizontalStackedComponent } from './single-statlet/graph-types/single-statlet-bar-horizontal-stacked/single-statlet-bar-horizontal-stacked.component';
import { SingleStatletBarVerticalStackedComponent } from './single-statlet/graph-types/single-statlet-bar-vertical-stacked/single-statlet-bar-vertical-stacked.component';
import { SingleStatletAreaStackedComponent } from './single-statlet/graph-types/single-statlet-area-stacked/single-statlet-area-stacked.component';
import { SingleStatletDonutComponent } from './single-statlet/graph-types/single-statlet-donut/single-statlet-donut.component';
import { StatletMetadataDataTypeService } from './single-statlet/data-type-services/statlet-metadata-data-type.service';
import { StatletCountryCodeUserDataTypeService } from './single-statlet/data-type-services/statlet-country-code-user-data-type.service';
import { SharedReportsModule } from '../../shared-reports/shared-reports.module';
import { SingleStatletWrapperDirective } from './single-statlet/single-statlet-wrapper.directive';
import { SingleStatletWrapperComponent } from './single-statlet/single-statlet-wrapper.component';
import { SingleStatletWrapperLoaderComponent } from './single-statlet/single-statlet-wrapper-loader.component';
import { SingleStatletInlineComponent } from './single-statlet/single-statlet-inline/single-statlet-inline.component';
import { SingleStatletNumberInlineComponent } from './single-statlet/graph-types/single-statlet-number/inline/single-statlet-number-inline.component';
import { CustomNumberInlineComponent } from './single-statlet/graph-types/single-statlet-number/inline/ngx-custom-number-inline/ngx-custom-number-inline.component';
import { CustomCardSeriesComponent } from './single-statlet/graph-types/single-statlet-number/inline/ngx-custom-number-inline/ngx-custom-card-series-inline.component';
import { SingleStatletBarVerticalInlineComponent } from './single-statlet/graph-types/single-statlet-bar-vertical/inline/single-statlet-bar-vertical-inline.component';
import { CustomBarVerticalInlineComponent } from './single-statlet/graph-types/single-statlet-bar-vertical/inline/ngx-custom-bar-vertical-inline/ngx-custom-bar-vertical-inline.component';
import { CustomGaugeInlineComponent } from './single-statlet/graph-types/single-statlet-gauge/inline/ngx-custom-gauge-inline/ngx-custom-gauge-inline.component';
import { SingleStatletGaugeInlineComponent } from './single-statlet/graph-types/single-statlet-gauge/inline/single-statlet-gauge-inline.component';
import { AtmireSharedModule } from '../../../shared/atmire-shared.module';
import { StatletI18nDataTypeService } from './single-statlet/data-type-services/statlet-i18n-data-type.service';

const ENTRY_COMPONENTS = [
  SingleStatletPieComponent,
  SingleStatletLineHorizontalComponent,
  SingleStatletBarHorizontalComponent,
  SingleStatletMapComponent,
  SingleStatletNumberComponent,
  SingleStatletBarVerticalComponent,
  SingleStatletTableComponent,
  SingleStatletGaugeComponent,
  SingleStatletAreaComponent,
  SingleStatletBarHorizontalStackedComponent,
  SingleStatletBarVerticalStackedComponent,
  SingleStatletAreaStackedComponent,
  SingleStatletDonutComponent,
  SingleStatletCardComponent,
  SingleStatletInlineComponent,
  SingleStatletNumberInlineComponent,
  SingleStatletBarVerticalInlineComponent,
  SingleStatletGaugeInlineComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AtmireSharedModule,
    SharedReportsModule,
    NgxChartsModule,
    AmchartsModule,
  ],
  declarations: [
    ...ENTRY_COMPONENTS,
    StatletsSectionComponent,
    DynamicSingleStatletComponent,
    MergedSingleStatletComponent,
    SingleStatletDirective,
    SingleStatletGraphLoaderComponent,
    SingleStatletWrapperDirective,
    SingleStatletWrapperComponent,
    SingleStatletWrapperLoaderComponent,
    CustomNumberInlineComponent,
    CustomCardSeriesComponent,
    CustomBarVerticalInlineComponent,
    CustomGaugeInlineComponent,
  ],
  providers: [
    StatletDataService,
    StatletDefaultDataTypeService,
    StatletDsoCommunityDataTypeService,
    StatletDsoCollectionDataTypeService,
    StatletDsoItemDataTypeService,
    StatletCountryCodeDataTypeService,
    StatletCountryCodeUserDataTypeService,
    StatletSubjectDataTypeService,
    StatletMetadataDataTypeService,
    StatletI18nDataTypeService,
  ],
  exports: [
    StatletsSectionComponent,
    SingleStatletTableComponent,
    SingleStatletGraphLoaderComponent,
    SingleStatletWrapperLoaderComponent,
  ]
})
export class SharedStatletsModule {
  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during CSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: SharedModule,
      providers: ENTRY_COMPONENTS.map((component) => ({provide: component}))
    };
  }
}
