/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { StorageReport } from './atmire-cua/storage-reports/storage-reports-models/storage-report.model';
import { AtmireValuePair } from './atmire-cua/shared-reports/value-pairs/atmire-value-pair.model';
import { Statlet } from './atmire-cua/statlets/shared/data/models/statlet.model';
import { SearchTerm } from './atmire-cua/search-reports/search-reports-models/search-term.model';
import { SearchReport } from './atmire-cua/search-reports/search-reports-models/search-report.model';

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const MODELS = [
  StorageReport,
  SearchReport,
  SearchTerm,
  Statlet,
  AtmireValuePair,
];
