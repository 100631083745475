import {ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { BitstreamDataService } from '../../../../core/data/bitstream-data.service';
import { ConceptDataService } from '../../../../core/data/concept-data.service';
import { SiteDataService } from '../../../../core/data/site-data.service';
import { RouteService } from '../../../../core/services/route.service';
import { Concept } from '../../../../core/shared/concept.model';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { getItemPageRoute } from '../../../item-page-routing-paths';
import { ItemComponent } from '../shared/item.component';


/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Cruise', ViewMode.StandalonePage)
@Component({
  selector: 'ds-cruise',
  styleUrls: ['./cruise.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './cruise.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CruiseComponent extends ItemComponent implements OnInit {

  doi: BehaviorSubject<string> = new BehaviorSubject<string>({} as string);

  constructor(private renderer2: Renderer2,
              @Inject(DOCUMENT) private _document,
              protected routeService: RouteService,
              protected bitstreamDataService: BitstreamDataService,
              protected conceptDataService: ConceptDataService,
              public siteService: SiteDataService) {
    super(routeService, siteService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.itemPageRoute = getItemPageRoute(this.object);

    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
    this.renderer2.appendChild(this._document.body, s);
    if (this.object.hasMetadata('dc.identifier.doi')) {
      this.doi.next(this.object.firstMetadataValue('dc.identifier.doi'));
    }
  }
}
