<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'orgunit.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['organization.legalName'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'orgunit.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async"
                    [defaultImage]="'assets/images/orgunit-placeholder.svg'"
                    [alt]="'thumbnail.orgunit.alt'"
                    [placeholder]="'thumbnail.orgunit.placeholder'"
      >
      </ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['organization.foundingDate']"
                                [label]="'orgunit.page.dateestablished'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['organization.address.addressLocality']"
                                [label]="'orgunit.page.city'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['organization.adress.addressCountry']"
                                [label]="'orgunit.page.country'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier']"
                                [label]="'orgunit.page.id'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPublicationOfOrgUnit'"
      [label]="'relationships.isPublicationOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.description']"
                                [label]="'orgunit.page.description'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isOrgUnitOfPerson',
                                          filter: 'isOrgUnitOfPerson',
                                          configuration: 'person'
                                        },
                                        {
                                          label: 'isOrgUnitOfProject',
                                          filter: 'isOrgUnitOfProject',
                                          configuration: 'project'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
