/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { SearchModule } from '../../app/shared/search/search.module';
import { FormsModule } from '@angular/forms';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { JournalSearchResultGridElementComponent } from './app/entity-groups/journal-entities/item-grid-elements/search-result-grid-elements/journal/journal-search-result-grid-element.component';
import { JournalIssueSearchResultGridElementComponent } from './app/entity-groups/journal-entities/item-grid-elements/search-result-grid-elements/journal-issue/journal-issue-search-result-grid-element.component';
import { JournalVolumeSearchResultGridElementComponent } from './app/entity-groups/journal-entities/item-grid-elements/search-result-grid-elements/journal-volume/journal-volume-search-result-grid-element.component';
import { JournalSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal/journal-search-result-list-element.component';
import { JournalIssueSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-issue/journal-issue-search-result-list-element.component';
import { JournalVolumeSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-volume/journal-volume-search-result-list-element.component';
import { JournalComponent } from './app/entity-groups/journal-entities/item-pages/journal/journal.component';
import { JournalIssueComponent } from './app/entity-groups/journal-entities/item-pages/journal-issue/journal-issue.component';
import { JournalVolumeComponent } from './app/entity-groups/journal-entities/item-pages/journal-volume/journal-volume.component';
import { OrgUnitSearchResultGridElementComponent } from './app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/org-unit/org-unit-search-result-grid-element.component';
import { PersonSearchResultGridElementComponent } from './app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/person/person-search-result-grid-element.component';
import { ProjectSearchResultGridElementComponent } from './app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/project/project-search-result-grid-element.component';
import { OrgUnitSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component';
import { PersonSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/person/person-search-result-list-element.component';
import { ProjectSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/project/project-search-result-list-element.component';
import { OrgUnitComponent } from './app/entity-groups/research-entities/item-pages/org-unit/org-unit.component';
import { PersonComponent } from './app/entity-groups/research-entities/item-pages/person/person.component';
import { ProjectComponent } from './app/entity-groups/research-entities/item-pages/project/project.component';
import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { ItemSearchResultGridElementComponent } from './app/shared/object-grid/search-result-grid-element/item-search-result/item/item-search-result-grid-element.component';
import { ClaimedSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-search-result-list-element.component';
import { PoolSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/pool-search-result/pool-search-result-list-element.component';
import { WorkflowItemSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/workflow-item-search-result/workflow-item-search-result-list-element.component';
import { WorkspaceItemSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/workspace-item-search-result/workspace-item-search-result-list-element.component';
import { ClaimedTaskSearchResultDetailElementComponent } from './app/shared/object-detail/my-dspace-result-detail-element/claimed-task-search-result/claimed-task-search-result-detail-element.component';
import { ItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { ItemSharedModule } from '../../app/item-page/item-shared.module';
import { AtmireSavedItemListOtherModule } from '../../app-atmire/atmire-saved-item-list/atmire-saved-item-list-other/atmire-saved-item-list-other.module';
import { ItemListPreviewComponent } from './app/shared/object-list/my-dspace-result-list-element/item-list-preview/item-list-preview.component';
import { SharedStatletsModule } from '../../app-atmire/atmire-cua/statlets/shared/shared-statlets.module';
import { NavbarComponent } from './app/navbar/navbar.component';
import { HeaderComponent } from './app/header/header.component';
import { FooterComponent } from './app/footer/footer.component';
import { CruiseComponent } from './app/item-page/simple/item-types/cruise/cruise.component';
import { MetadataUriTextValuesComponent } from './app/item-page/field-components/metadata-uri-text-values/metadata-uri-text-values.component';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  JournalSearchResultGridElementComponent,
  JournalIssueSearchResultGridElementComponent,
  JournalVolumeSearchResultGridElementComponent,
  JournalSearchResultListElementComponent,
  JournalIssueSearchResultListElementComponent,
  JournalVolumeSearchResultListElementComponent,
  JournalComponent,
  JournalIssueComponent,
  JournalVolumeComponent,
  OrgUnitSearchResultGridElementComponent,
  PersonSearchResultGridElementComponent,
  ProjectSearchResultGridElementComponent,
  OrgUnitSearchResultListElementComponent,
  PersonSearchResultListElementComponent,
  ProjectSearchResultListElementComponent,
  OrgUnitComponent,
  PersonComponent,
  ProjectComponent,
  PublicationComponent,
  CruiseComponent,
  UntypedItemComponent,
  ItemSearchResultGridElementComponent,
  ClaimedSearchResultListElementComponent,
  PoolSearchResultListElementComponent,
  WorkflowItemSearchResultListElementComponent,
  WorkspaceItemSearchResultListElementComponent,
  ClaimedTaskSearchResultDetailElementComponent,
  ItemSearchResultListElementComponent,
  ItemListPreviewComponent,
  MetadataUriTextValuesComponent
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  HeaderComponent,
  NavbarComponent,
  FooterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SearchModule,
    FormsModule,
    RootModule,
    NavbarModule,
    ItemPageModule,
    ItemSharedModule,
    AtmireSavedItemListOtherModule,
    SharedStatletsModule,
  ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})
/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}
