<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-metadata-field-wrapper [label]="label | translate">
    <ng-container *ngFor="let mdValue of mdValues; let last=last;">
        <a *ngIf="testLink(mdValue.value) else span" [href]="mdValue.value">
            {{ linktext || mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
        </a>
        <ng-template #span>
            <span>{{ linktext || mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span></span>
        </ng-template>
        <br/>
    </ng-container>
</ds-metadata-field-wrapper>
