<ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
<ds-truncatable [id]="dso.id">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
       [routerLink]="[itemPageRoute]" class="lead"
       [innerHTML]="firstMetadataValue('organization.legalName')"></a>
    <span *ngIf="linkType == linkTypes.None"
          class="lead"
          [innerHTML]="firstMetadataValue('organization.legalName')"></span>
    <span class="text-muted">
    <ds-truncatable-part [id]="dso.id" [minLines]="3">
            <span *ngIf="dso.allMetadata(['dc.description']).length > 0"
                  class="item-list-org-unit-description">
                <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                        [innerHTML]="firstMetadataValue('dc.description')"></span>
                </ds-truncatable-part>
            </span>
        </ds-truncatable-part>
  </span>
</ds-truncatable>
