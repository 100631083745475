<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-pagination [paginationOptions]="initialPaginationOptions"
               [sortOptions]="initialSortOptions"
               [pageInfoState]="(resultsRD$ | async)?.payload"
               [collectionSize]="(resultsRD$ | async)?.payload?.totalElements"
               [hideGear]="hideGear"
>
  <ds-atmire-object-collection [objects$]="results$"
                               [error$]="error$"
                               [isLoading$]="isLoading$"
                               [viewMode]="viewMode"
                               [context]="context"
                               [linkType]="linkType"
                               [noResultsMsg]="noResultsMsg"
  ></ds-atmire-object-collection>
</ds-pagination>
