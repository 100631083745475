import {ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2} from '@angular/core';
import { CruiseComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/cruise/cruise.component';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';


/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Cruise', ViewMode.StandalonePage, undefined, 'atmire')
@Component({
  selector: 'ds-cruise',
  styleUrls: ['./cruise.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './cruise.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CruiseComponent extends BaseComponent {
}
