import { Component, Input, OnInit} from '@angular/core';
import { Item } from '../../../core/shared/item.model';

@Component({
  selector: 'ds-cc-license',
  styleUrls: ['./cc-license.component.scss'],
  templateUrl: './cc-license.component.html',
})
export class CCLicenseComponent implements OnInit {

  @Input()
  item: Item;
  ccLicenseName: string;
  ccLicenseURI: string;
  imgSource: string;

  ngOnInit(): void {
    if (this.item.hasMetadata('dc.rights')) {
      this.ccLicenseName = "Except where otherwise noted, this item's license is described as " + this.item.firstMetadata('dc.rights').value;
    }
    if (this.item.hasMetadata('dc.rights.uri')) {
      this.ccLicenseURI = this.item.firstMetadata('dc.rights.uri').value;
    }
    if (this.ccLicenseURI) {
      switch (this.ccLicenseURI.split('/')[4]) {
        case 'by':
          this.imgSource = 'assets/atmire/images/cc-license/cc-by.png';
          break;
        case 'by-nc':
          this.imgSource = 'assets/atmire/images/cc-license/cc-by-nc.png';
          break;
        case 'by-nc-nd':
          this.imgSource = 'assets/atmire/images/cc-license/cc-by-nc-nd.png';
          break;
        case 'by-nc-sa':
          this.imgSource = 'assets/atmire/images/cc-license/cc-by-nc-sa.png';
          break;
        case 'by-nd':
          this.imgSource = 'assets/atmire/images/cc-license/cc-by-nd.png';
          break;
        case 'by-sa':
          this.imgSource = 'assets/atmire/images/cc-license/cc-by-sa.png';
          break;
        case 'mark':
          this.imgSource = 'assets/atmire/images/cc-license/cc-mark.png';
          break;
        case 'zero':
          this.imgSource = 'assets/atmire/images/cc-license/cc-zero.png';
          break;
        default:
          this.imgSource = 'assets/atmire/images/cc-license/cc-generic.png';
          break;
      }
    }
  }

}
