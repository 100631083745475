/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2} from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { PublicationComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';
import {BehaviorSubject} from 'rxjs';
import {Concept} from '../../../../../../../app/core/shared/concept.model';
import {RouteService} from '../../../../../../../app/core/services/route.service';
import {BitstreamDataService} from '../../../../../../../app/core/data/bitstream-data.service';
import {ConceptDataService} from '../../../../../../../app/core/data/concept-data.service';
import {SiteDataService} from '../../../../../../../app/core/data/site-data.service';
import {getItemPageRoute} from '../../../../../../../app/item-page/item-page-routing-paths';
import {DOCUMENT} from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ItemDataService } from '../../../../../../../app/core/data/item-data.service';
import { VersionDataService } from '../../../../../../../app/core/data/version-data.service';
import { VersionHistoryDataService } from '../../../../../../../app/core/data/version-history-data.service';
import { SearchService } from '../../../../../../../app/core/shared/search/search.service';
import { WorkspaceitemDataService } from '../../../../../../../app/core/submission/workspaceitem-data.service';
import { ItemVersionsSharedService } from '../../../../../../../app/shared/item/item-versions/item-versions-shared.service';

@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  templateUrl: './publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@listableObjectComponent('Publication', ViewMode.StandalonePage, undefined, 'atmire')
export class PublicationComponent extends BaseComponent {

  goal$: BehaviorSubject<Concept>;
  doi: BehaviorSubject<string> = new BehaviorSubject<string>({} as string);

  constructor(modalService: NgbModal,
              versionHistoryService: VersionHistoryDataService,
              translateService: TranslateService,
              versionService: VersionDataService,
              itemVersionShared: ItemVersionsSharedService,
              router: Router,
              workspaceItemDataService: WorkspaceitemDataService,
              searchService: SearchService,
              itemService: ItemDataService,
              private renderer2: Renderer2,
              @Inject(DOCUMENT) private _document,
              protected routeService: RouteService,
              protected bitstreamDataService: BitstreamDataService,
              protected conceptDataService: ConceptDataService,
              public siteService: SiteDataService) {
    super(modalService, versionHistoryService, translateService, versionService, itemVersionShared, router, workspaceItemDataService, searchService, itemService, routeService, siteService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    console.log(this.object?.allMetadata(['dc.relation.ispartof']))
    this.itemPageRoute = getItemPageRoute(this.object);

    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
    this.renderer2.appendChild(this._document.body, s);
    if (this.object.hasMetadata('dc.identifier.doi')) {
      this.doi.next(this.object.firstMetadataValue('dc.identifier.doi'));
    }
  }

  DatePassed(date: string) {
    return new Date(date) > new Date();
  }
}
