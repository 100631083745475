<!-- If $ccLicenseUri contains "creativecommons" -->
<ng-container *ngIf="ccLicenseName && ccLicenseURI && imgSource">
  <div class="cc-license">
    <div class="col-sm-3 col-xs-12 license-img">
      <a rel="license"
         href="{{ccLicenseURI}}"
         title="{{ccLicenseName}}">
        <img src="{{imgSource}}">
      </a>
    </div>
    <div class="col-sm-10 license-text">
      {{ccLicenseName}}
    </div>
  </div>
</ng-container>
