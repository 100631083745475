import { Component, Input, OnInit } from '@angular/core';
import { result } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DSpaceObjectDataService } from '../../../../../../../core/data/dspace-object-data.service';
import { LocaleService } from '../../../../../../../core/locale/locale.service';
import { Concept } from '../../../../../../../core/shared/concept.model';
import { Item } from '../../../../../../../core/shared/item.model';
import { getItemPageRoute } from '../../../../../../../item-page/item-page-routing-paths';

@Component({
  selector: 'ds-category-path',
  styleUrls: ['./category-path.component.scss'],
  templateUrl: './category-path.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class CategoryPathComponent {

    @Input() categories: Concept[];

    constructor(protected dspaceObjectDataService: DSpaceObjectDataService,
                protected localService: LocaleService) {
    }

    categoryPageRoute(concept: Concept): BehaviorSubject<string> {
        let route: BehaviorSubject<string>;
        // tslint:disable-next-line: deprecation no-shadowed-variable
        this.dspaceObjectDataService.findById(concept.uuid).subscribe((result) => {
            if (result.hasCompleted) {
                route = new BehaviorSubject(getItemPageRoute(result.payload as Item));
            }
        });

        return route;
    }

    getLabel(concept: Concept): string {
        // tslint:disable-next-line: no-string-literal
        let label = concept.language_label[this.localService.getCurrentLanguageCode()];
        if (!label) {
            label = concept.language_label['en'];
        }
        return label;
    }
}
