<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'project.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'project.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail
        [thumbnail]="object?.thumbnail | async"
        [defaultImage]="'assets/images/project-placeholder.svg'"
        [alt]="'thumbnail.project.alt'"
        [placeholder]="'thumbnail.project.placeholder'">
      </ds-thumbnail>
    </ds-metadata-field-wrapper>
    <!--<ds-generic-item-page-field [item]="object"-->
      <!--[fields]="['project.identifier.status']"-->
      <!--[label]="'project.page.status'">-->
    <!--</ds-generic-item-page-field>-->
    <ds-metadata-representation-list
      [parentItem]="object"
      [itemType]="'OrgUnit'"
      [metadataFields]="['project.contributor.other']"
      [label]="'project.page.contributor' | translate">
    </ds-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
      [fields]="['project.identifier.funder']"
      [label]="'project.page.funder'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier']"
      [label]="'project.page.id'">
    </ds-generic-item-page-field>
    <!--<ds-generic-item-page-field [item]="object"-->
      <!--[fields]="['project.identifier.expectedcompletion']"-->
      <!--[label]="'project.page.expectedcompletion'">-->
    <!--</ds-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPersonOfProject'"
      [label]="'relationships.isPersonOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPublicationOfProject'"
      [label]="'relationships.isPublicationOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfProject'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'project.page.description'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [label]="'project.page.keyword'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
