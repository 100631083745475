/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import domtoimage from 'dom-to-image';
import { isNotEmpty } from '../../../../app/shared/empty.util';

export enum ImageType {
  SVG = '.svg',
  JPEG = '.jpeg',
  PNG = '.png'
}

export function getExportFunction(imageType: ImageType): (dom: Element, options?: object) => Promise<string> {
  switch (imageType) {
    case ImageType.JPEG: {
      return domtoimage.toJpeg;
    }
    case ImageType.PNG: {
      return domtoimage.toPng;
    }
    case ImageType.SVG: {
      return domtoimage.toSvg;
    }
  }
}


export function toCamelCase(fileName: string) {
  if (isNotEmpty(fileName)) {
    return fileName
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }
  return fileName;
}
