<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer"
                       [object]="object"
                       [searchable]="iiifSearchEnabled"
                       [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-version-button (newVersionEvent)="onCreateNewVersion()" [dso]="object"
                                [tooltipMsgCreate]="'item.page.version.create'"
                                [tooltipMsgHasDraft]="'item.page.version.hasDraft'"></ds-dso-page-version-button>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>

<!--    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"-->
<!--                                     [parentItem]="object"-->
<!--                                     [itemType]="'Person'"-->
<!--                                     [metadataFields]="['dc.contributor.author', 'dc.creator']"-->
<!--                                     [label]="'relationships.isAuthorOf' | translate">-->
<!--    </ds-metadata-representation-list>-->

    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.contributor.author']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-generic-item-page-field>

    <ds-related-items
      [parentItem]="object"
      [label]="'item.page.LinkedAuthors' | translate"
      [relationType]="'isAuthorOfPublication'"
    ></ds-related-items>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['journal.title']"
                                [label]="'item.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['journal.identifier.issn']"
                                [label]="'item.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['journalvolume.identifier.name']"
                                [label]="'item.page.volume-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.publisher']"
                                [label]="'item.page.publisher'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.description']"
                                [label]="'item.page.description'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject']"
                                [separator]="','"
                                [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.citation']"
                                [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ng-container *ngIf="object.firstMetadataValue('dc.description.embargo')">
      <h5>{{ 'item.page.embargo.title' | translate }}</h5>
      <p>{{ 'item.page.embargo' | translate: {year: object.firstMetadataValue('dc.description.embargo')} }}</p>
    </ng-container>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']" role="button">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
