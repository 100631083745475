/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { hasValue } from '../../../../app/shared/empty.util';
import { ExportAsImageModalComponent } from './export-as-image-modal/export-as-image-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { getExportFunction, ImageType, toCamelCase } from './export-as-image.utils';

/*
  Disabled ImageType.SVG: it works but the exported files are very large
 */
export const EXPORT_IMAGE_TYPES = [ImageType.PNG, ImageType.JPEG];

@Directive({
  selector: '[dsExportAsImage]'
})
export class ExportAsImageDirective implements AfterViewInit {
  @Input() fileName = 'graph';
  @Input() formats = EXPORT_IMAGE_TYPES;
  @Input() format = EXPORT_IMAGE_TYPES[0];

  constructor(private element: ElementRef, private modalService: NgbModal) {
  }


  ngAfterViewInit(): void {
    const trigger: Element = this.element.nativeElement.querySelector('[dsExportAsImageTrigger]');
    // Check whether the trigger exists. If there's no chart it doesn't
    if (hasValue(trigger)) {
      trigger.addEventListener('click', this.openModal.bind(this));
    }
  }

  private openModal() {
    const modalRef: NgbModalRef = this.modalService.open(ExportAsImageModalComponent);
    modalRef.componentInstance.fileName = toCamelCase(this.fileName);
    modalRef.componentInstance.fileTypes = EXPORT_IMAGE_TYPES;
    modalRef.result.then(([fileName, format]) => {
      this.saveAsImage(fileName, format);
    }, () => {/* Do nothing on reject */
    });
  }

  private saveAsImage(fileName: string, format: ImageType) {
    const dom: Element = this.element.nativeElement.querySelector('[dsExportAsImageDom]');
    if (hasValue(dom)) {
      getExportFunction(format)(dom, {
        bgcolor: 'white',
      }).then((dataUrl) => {
        const link = document.createElement('a');
        link.download = fileName + format;
        link.href = dataUrl;
        link.click();
      });
    }
  }

}
