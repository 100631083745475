<footer class="text-lg-start custom">
  <hr>
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class="content-container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-8 col-s-8 center">

          <ul class="list-unstyled">
            <li>

                <a href="https://www.coretrustseal.org/"><img class="core-trust" src="assets/atmire/images/logo-CTS.png"></a>

            </li>
            <li>

                <p style="padding-top: 20px; text-align: left; font-style: italic; font-size: 12px">All Items in WHOAS are protected by
                  original copyright, with all rights reserved, unless otherwise indicated. WHOAS also supports the use
                  of Creative Commons licenses for original content.</p>

            </li>
            <li>
              <b style="font-size: 15px">Connect with MBLWHOI Library</b>
            </li>
            <li>
                <a href="https://www.facebook.com/mblwhoilibrary/" style="padding: 8px">
                  <img img width="60px" src="assets/atmire/images/facebook-icon.png"></a>
                <a href="https://twitter.com/mblwhoilibrary" style="padding: 8px">
                  <img img width="60px" src="assets/atmire/images/twitter-icon.png"></a>
            </li>
            <li>
              <p style="font-size: 15px;">MBLWHOI Library | 7 MBL Street Woods Hole, MA 02543 | 508-289-7002</p>
            </li>
            <li>
              <p style="font-size: 14px;"><a href="https://mblwhoilibrary.org/mblwhoi-library-privacy-statement">Privacy
                Policy</a> | <a href="https://mblwhoilibrary.org/about-the-library/mblwhoi-library-staff-directory/">Contact Us</a> |
                <a href="https://mblwhoilibrary.org/services/whoas-repository-services">About WHOAS</a></p>
            </li>
            <li>
              <p style="font-size: 12px;">© 2022 MBLWHOI Library</p>
            </li>
            <li>
              <a href="https://www.whoi.edu/">
                <img width="200px" style="margin: 10px" src="assets/atmire/images/WHOI-logo.png"></a>
              <a href="https://www.mbl.edu/">
                <img img width="200px" style="margin: 10px" src="assets/atmire/images/MBL-logo.png"></a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright

  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        <a class="text-white"
           href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>

  Copyright -->
</footer>
