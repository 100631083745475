<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-version-button (newVersionEvent)="onCreateNewVersion()" [dso]="object"
      [tooltipMsgCreate]="'item.page.version.create'" [tooltipMsgHasDraft]="'item.page.version.hasDraft'">
    </ds-dso-page-version-button>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
  <div *ngIf="doi.getValue().length>0" data-badge-popover="left" data-badge-type="1" [attr.data-doi]="doi.getValue()" data-hide-no-mentions="false" class="altmetric-embed altmetricHead"></div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>

<!--    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"-->
<!--                                     [parentItem]="object"-->
<!--                                     [itemType]="'Person'"-->
<!--                                     [metadataFields]="['dc.contributor.author', 'dc.creator']"-->
<!--                                     [label]="'relationships.isAuthorOf' | translate">-->
<!--    </ds-metadata-representation-list>-->

    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.contributor.author']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-generic-item-page-field>

    <ds-related-items
      [parentItem]="object"
      [label]="'item.page.LinkedAuthors' | translate"
      [relationType]="'isAuthorOfPublication'"
    ></ds-related-items>

    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <div *ngIf="doi.getValue().length>0"  data-badge-details="right" data-badge-type="medium-donut" [attr.data-doi]="doi.getValue()" data-condensed="true" data-hide-no-mentions="false" class="altmetric-embed"></div>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.title.alternative']"
                                [label]="'publication.page.title.alternative'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'publication.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.relation.uri']"
                            [label]="'publication.page.relation.uri'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.date.created']"
                            [label]="'item.page.date.created'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.coverage.spatial']"
                            [label]="'item.page.coverage.spatial'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.identifier.doi']"
                            [label]="'item.page.identifier.doi'">
    </ds-generic-item-page-field>
    <ds-metadata-uri-text-values [mdValues]="object?.allMetadata(['dc.relation.ispartof'])" 
                            [label]="'item.page.isPartOf'">
    </ds-metadata-uri-text-values>
    <ds-metadata-uri-text-values [mdValues]="object?.allMetadata(['dc.relation.replaces'])"
                            [label]="'item.page.replaces'">
    </ds-metadata-uri-text-values>
    <ds-metadata-uri-text-values [mdValues]="object?.allMetadata(['dc.relation.isreplacedby'])"
                            [label]="'item.page.replacedby'">
    </ds-metadata-uri-text-values>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.subject']"
                            [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.description']"
                            [label]="'item.page.description'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.description.ebmargo']"
                            [label]="'item.page.description.embargo'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.identifier.citation']"
                            [label]="'item.page.citation'">
    </ds-generic-item-page-field>

    <!-- Cruise Virtual Metadata -->
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
                            [parentItem]="object"
                            [itemType]="'Concept'"
                            [metadataFields]="['dc.description.cruisename']"
                            [label]="'relationships.isCruiseOf' | translate">
    </ds-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.identifier.cruiseid']"
                            [label]="'cruise.page.cruiseId'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.identifier.cruisedoi']"
                            [label]="'cruise.page.cruiseDoi'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                            [fields]="['dc.description.vesselname']"
                            [label]="'cruise.page.vesselName'">
    </ds-generic-item-page-field>
    <ng-container *ngIf="object.firstMetadataValue('dc.description.embargo') && DatePassed(object.firstMetadataValue('dc.description.embargo'))">
      <h5>{{ 'item.page.embargo.title' | translate }}</h5>
      <p>{{ 'item.page.embargo' | translate: {year: object.firstMetadataValue('dc.description.embargo')} }}</p>
    </ng-container>

    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <ds-cc-license [item]="object"></ds-cc-license>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
