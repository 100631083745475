<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'journalissue.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'journalissue.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-generic-item-page-field [item]="object"
      [fields]="['publicationvolume.volumeNumber']"
      [label]="'journalvolume.page.volume'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['publicationissue.issueNumber']"
      [label]="'journalissue.page.number'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['creativework.datePublished']"
      [label]="'journalissue.page.issuedate'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'journalissue.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['creativeworkseries.issn']"
      [label]="'journalissue.page.journal-issn'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalVolumeOfIssue'"
      [label]="'relationships.isSingleVolumeOf' | translate">
    </ds-related-items>
    <ds-related-items
      class="mb-1 mt-1"
      [parentItem]="object"
      [relationType]="'isPublicationOfJournalIssue'"
      [label]="'relationships.isPublicationOfJournalIssue' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'journalissue.page.description'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['creativework.keywords']"
      [label]="'journalissue.page.keyword'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
