import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ItemComponent } from '../shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { getItemPageRoute } from '../../../item-page-routing-paths';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Concept', ViewMode.StandalonePage)
@Component({
  selector: 'ds-concept-item',
  styleUrls: ['./concept-item.component.scss'],
  templateUrl: './concept-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptItemComponent extends ItemComponent {

  linkedIn: string = 'http://www.linkedin.com/shareArticle?mini=true&url=' + location.href;

  twitter: string = 'http://twitter.com/share?url=' + location.href;

  facebook: string = 'http://www.facebook.com/sharer.php?u=' + location.href;

  switchCase = 'publications';

  type: string;

  switch(label: string) {
    this.switchCase = label;
  }

  ngOnInit() {
    super.ngOnInit();
    this.site$ = this.siteService.find();
    this.itemPageRoute = getItemPageRoute(this.object);
    this.type = this.object.firstMetadataValue('dc.type').toLowerCase();
  }
}
