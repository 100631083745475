/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SearchOptions } from '../../app/shared/search/models/search-options.model';
import { AbstractListableObjectSourceModel, AbstractSourceKwargs } from './sources/abstract-listable-object-source';
import { AtmireHrefListableObjectSource } from './sources/atmire-href-listable-object-source';
import { AtmireSearchListableObjectSource } from './sources/atmire-search-listable-object-source';
import { DSpaceObject } from '../../app/core/shared/dspace-object.model';

// tslint:disable:max-classes-per-file

/**
 * An enum listing the available implementations of {@link AbstractListableObjectSource}.
 * When defining a new object source, make sure to include add an entry to this enum
 * and use that entry as the source value of its model.
 */
export enum ListableObjectSourceType {
  HREF, SEARCH,
}

/**
 * Resolves to an {@link AtmireHrefListableObjectSource}, which retrieves objects via {@link HrefOnlyDataService}.
 */
export class AtmireHrefListableObjectSourceModel<T extends DSpaceObject> extends AbstractListableObjectSourceModel<T> {
  readonly source = ListableObjectSourceType.HREF;

  constructor(public href: string, kwargs?: AbstractSourceKwargs<T>) {
    super(kwargs);
  }
}

/**
 * Resolves to an {@link AtmireHrefListableObjectSource}, which retrieves objects via {@link SearchService}.
 */
export class AtmireSearchListableObjectSourceModel<T extends DSpaceObject> extends AbstractListableObjectSourceModel<T> {
  readonly source = ListableObjectSourceType.SEARCH;

  constructor(
    public searchOptions: Partial<SearchOptions>,
    public displayAsSearchResults = true,
    kwargs?: AbstractSourceKwargs<T>
  ) {
    super(kwargs);
  }
}

/**
 * All available implementations of {@link AbstractListableObjectSourceModel}.
 * When defining a new object source, make sure to include it in this type.
 */
export type ListableObjectSourceModel
  = AtmireHrefListableObjectSourceModel<any>
    | AtmireSearchListableObjectSourceModel<any>;

/**
 * All available implementations of {@link AbstractListableObjectSource}.
 * When defining a new object source, make sure to include it in this type.
 */
export type ListableObjectSource
  = AtmireHrefListableObjectSource<any>
    | AtmireSearchListableObjectSource<any>;
