<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title', 'dc.description.vessel_name', 'dc.identifier.cruise_id'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'cruise.page.edit'"></ds-dso-page-edit-button>
  </div>
  <div *ngIf="doi.getValue().length>0"  data-badge-details="right" data-badge-type="medium-donut" [attr.data-doi]="doi.getValue()" data-condensed="true" data-hide-no-mentions="false" class="altmetric-embed"></div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.date.departdate']" [label]="'cruise.page.departureDate'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.coverage.departPort']" [label]="'cruise.page.departurePort'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.date.arrivedate']" [label]="'cruise.page.arriveDate'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.coverage.arrivePort']" [label]="'cruise.page.arrivePort'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.coverage.spatial']" [label]="'cruise.page.spatial'">
    </ds-generic-item-page-field>
    <div *ngIf="doi.getValue().length>0"  data-badge-details="right" data-badge-type="medium-donut" [attr.data-doi]="doi.getValue()" data-condensed="true" data-hide-no-mentions="false" class="altmetric-embed"></div>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.title.alternative']"
                                [label]="'cruise.page.cruiseName'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.cruiseid']"
                                [label]="'cruise.page.cruiseId'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object" 
                            [fields]="['dc.identifier.cruiseuri']" 
                            [label]="'cruise.page.cruiseUri'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" 
                            [fields]="['dc.identifier.cruisedoi']" 
                            [label]="'cruise.page.cruiseDoi'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.description.vesselname']"
                                [label]="'cruise.page.vesselName'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.chiefScientist']"
                                [label]="'cruise.page.chiefScientist'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject']"
                                [separator]="','"
                                [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ng-container *ngIf="object.firstMetadataValue('dc.description.embargo')">
      <h5>{{ 'item.page.embargo.title' | translate }}</h5>
      <p>{{ 'item.page.embargo' | translate: {year: object.firstMetadataValue('dc.description.embargo')} }}</p>
    </ng-container>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <ds-cc-license [item]="object"></ds-cc-license>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isCruiseOfPublication',
                                          filter: 'isCruiseOfPublication'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
