/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { hasNoValue, isNotEmpty } from '../../../../../app/shared/empty.util';

@Component({
  selector: 'ds-export-as-image-modal',
  templateUrl: './export-as-image-modal.component.html',
  styleUrls: ['./export-as-image-modal.component.scss']
})
export class ExportAsImageModalComponent implements OnInit {
  @Input() fileName;
  @Input() fileTypes: string[];
  @Input() fileType: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (hasNoValue(this.fileType) && isNotEmpty(this.fileTypes)) {
      this.fileType = this.fileTypes[0];
    }
  }

}
