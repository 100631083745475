import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DSpaceObjectDataService } from '../../../../../../../core/data/dspace-object-data.service';
import { LocaleService } from '../../../../../../../core/locale/locale.service';
import { Concept } from '../../../../../../../core/shared/concept.model';
import { Item } from '../../../../../../../core/shared/item.model';
import { getItemPageRoute } from '../../../../../../../item-page/item-page-routing-paths';

@Component({
  selector: 'ds-goal-pill',
  styleUrls: ['./goal-pill.component.scss'],
  templateUrl: './goal-pill.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class GoalPillComponent implements OnInit {

    @Input() goal: Concept;

    label: string;

    goalPageRoute$: BehaviorSubject<string>;

    constructor(protected dspaceObjectDataService: DSpaceObjectDataService,
                protected localService: LocaleService) {
    }

    ngOnInit(): void {
        // tslint:disable-next-line: no-string-literal
        this.label = this.goal.language_label[this.localService.getCurrentLanguageCode()];
        if (!this.label) {
            this.label = this.goal.language_label['en'];
        }
        // tslint:disable-next-line: deprecation
        this.dspaceObjectDataService.findById(this.goal.uuid).subscribe((result) => {
            if (result.hasCompleted) {
                this.goalPageRoute$ = new BehaviorSubject(getItemPageRoute(result.payload as Item));
            }
        });
    }
}
