import {Component} from '@angular/core';
import {NavbarComponent as BaseComponent} from '../../../../app/navbar/navbar.component';
// import {slideMobileNav} from '../../../../app/shared/animations/slide';
import {MenuID, MenuItemType} from '../../../../app/shared/menu/initial-menus-state';
import {ExternalMenuItemModel} from '../../../../app/shared/menu/menu-item/models/external.model';
/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  // styleUrls: ['./navbar.component.scss'],
  styleUrls: ['../../../../app/navbar/navbar.component.scss'],
  // templateUrl: './navbar.component.html',
  templateUrl: '../../../../app/navbar/navbar.component.html',
  // animations: [slideMobileNav]
})
export class NavbarComponent extends BaseComponent {

  ngOnInit() {
    super.ngOnInit();
    const aboutMenu = {
      id: `aboutWHOAS`,
      active: false,
      visible: true,
      index: 1,
      model: {
        type: MenuItemType.EXTERNAL,
        text: 'menu.section.about-whoas.label',
        link: `https://mblwhoilibrary.org/services/whoas-repository-services/`
      } as ExternalMenuItemModel
    };
    this.menuService.addSection(MenuID.PUBLIC, Object.assign(aboutMenu, {
      shouldPersistOnRouteChange: true
    }));
  }

}
