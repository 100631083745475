import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { BehaviorSubject } from 'rxjs';
import { getItemPageRoute } from '../../../../item-page/item-page-routing-paths';
import { Concept } from '../../../../core/shared/concept.model';
import { VersionedItemComponent } from '../versioned-item/versioned-item.component';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage)
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  templateUrl: './publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends VersionedItemComponent implements OnInit{

  goal$: BehaviorSubject<Concept>;

  ngOnInit(): void {
    super.ngOnInit();
    this.itemPageRoute = getItemPageRoute(this.object);
  }
}
