<ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
<ds-truncatable [id]="dso.id">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
       [routerLink]="[itemPageRoute]" class="lead"
       [innerHTML]="name"></a>
    <span *ngIf="linkType == linkTypes.None"
          class="lead"
          [innerHTML]="firstMetadataValue('person.familyName') + ', ' + firstMetadataValue('person.givenName')"></span>
    <span class="text-muted">
    <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <span *ngIf="dso.allMetadata(['person.jobTitle']).length > 0"
                  class="item-list-job-title">
                    <span *ngFor="let value of allMetadataValues(['person.jobTitle']); let last=last;">
                        <span [innerHTML]="value"><span [innerHTML]="value"></span></span>
                    </span>
            </span>
        </ds-truncatable-part>
  </span>
</ds-truncatable>
