/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AtmireShowMoreComponent } from './show-more/atmire-show-more.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { AtmirePaginationComponent } from './pagination/atmire-pagination.component';
import { AtmireObjectCollectionComponent } from './base/atmire-object-collection.component';
import { ListableObjectSourceFactoryService } from './sources/listable-object-source-factory.service';

const DECLARATIONS = [
  AtmireObjectCollectionComponent,
  AtmireShowMoreComponent,
  AtmirePaginationComponent,
];

const PROVIDERS = [
  ListableObjectSourceFactoryService,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    ...DECLARATIONS,
  ],
  exports: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
  ]
})
export class AtmireObjectCollectionModule {
}
