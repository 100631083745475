<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])">
    </ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
                                     [parentItem]="object"
                                     [itemType]="'Person'"
                                     [metadataFields]="['ags.creatorPersonal', 'ags.creatorCorporate']"
                                     [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <div class="citation" *ngIf="object?.allMetadata(['ags.creatorPersonal']).length > 0 || object?.allMetadata(['ags.creatorPersonal']).length > 0">
      <h5>{{'item.page.citation' | translate}}</h5>
    </div>
    <ds-generic-item-page-field [item]="object"
                                  [fields]="['dc.description']"
                                  [label]="'item.page.description'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isNarrowerConcept'"
      [label]="'relationships.isNarrowerConcept' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isBroaderConcept'"
      [label]="'relationships.​isBroaderConcept' | translate">
    </ds-related-items>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
<!--<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>-->
