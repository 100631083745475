import { autoserialize, deserialize, inheritSerialization } from 'cerialize';

import { typedObject } from '../cache/builders/build-decorators';
import { CacheableObject } from '../cache/object-cache.reducer';
import { excludeFromEquals } from '../utilities/equals.decorators';
import { HALLink } from './hal-link.model';
import { JSONDATA } from './json.resource-type';
import { ResourceType } from './resource-type';

@typedObject
export class JsonData implements CacheableObject {
  static type = JSONDATA;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  @autoserialize
  json: string;
  /**
   * The {@link HALLink}s for this Bitstream
   */
   @deserialize
  _links: {
    self: HALLink;
  };
}
