import { Component, Input, OnInit } from '@angular/core';
import { DSpaceObject } from '../../../../../../../core/shared/dspace-object.model';
import { Item } from '../../../../../../../core/shared/item.model';
import { getItemPageRoute } from '../../../../../../../item-page/item-page-routing-paths';

@Component({
  selector: 'ds-area-pill',
  styleUrls: ['./area-pill.component.scss'],
  templateUrl: './area-pill.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class AreaPillComponent implements OnInit {

    @Input() areaOfWork: DSpaceObject;

    title: string;

    link: string;

    ngOnInit() {
      this.link = getItemPageRoute(this.areaOfWork as Item);
      this.title = this.areaOfWork.firstMetadataValue('dc.title');
    }
}
