<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'export-as-image.modal.file-name.head' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="fileName">{{'export-as-image.modal.file-name.label' | translate}}</label>
            <div class="input-group">
                <input id="fileName" class="form-control" [(ngModel)]="fileName" [ngModelOptions]="{standalone: true}" name="dp">
                <div class="input-group-append">
                    <select [(ngModel)]="fileType" [ngModelOptions]="{standalone: true}" class="custom-select">
                        <option *ngFor="let type of fileTypes" [value]="type">{{type}}</option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.dismiss()">{{'export-as-image.modal.close' | translate}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close([fileName, fileType])">{{'export-as-image.modal.save' | translate}}</button>
</div>

