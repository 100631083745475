/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, Injector } from '@angular/core';
import { rendersSingleStatletForType } from '../single-statlet.decorator';
import { StatletGraphTypes } from '../statlet-graph-types.model';
import { HeatMapEntry } from '../../../../../../../app/amcharts/world-heat-map.component';
import { DynamicSingleStatletComponent } from '../dynamic-single-statlet.component';
import { Observable, combineLatest, of as observableOf } from 'rxjs';
import { Router } from '@angular/router';
import { AtmireCuaColorService } from '../../../../../shared/atmire-cua-color.service';
import {
  STATLET_DATA_TYPE_SERVICE_FACTORY
} from '../../data-type-services/statlet-data-type.decorator';
import { GenericConstructor } from '../../../../../../../app/core/shared/generic-constructor';
import { HostWindowService } from '../../../../../../../app/shared/host-window.service';
import {
  StatletCountryCodeDataTypeService
} from '../../data-type-services/statlet-country-code-data-type.service';
import { map, switchMap } from 'rxjs/operators';
import { AlternativeLabelService } from '../../../../../shared/alternative-label.service';
import { Context } from '../../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-atmire-cua-single-statlet-map',
  templateUrl: './single-statlet-map.component.html',
  styleUrls: ['./single-statlet-map.component.scss']
})
@rendersSingleStatletForType(StatletGraphTypes.MAP)
@rendersSingleStatletForType(StatletGraphTypes.MAP, Context.StatletCard)
/**
 * Component rendering a single statlet of type "map"
 */
export class SingleStatletMapComponent extends DynamicSingleStatletComponent {

  /**
   * Unique identifier for this statlet on the current route
   */
  id: string;

  labels$: Observable<any>;

  minColour;
  maxColour;

  constructor(protected parentInjector: Injector,
              protected router: Router,
              protected colorService: AtmireCuaColorService,
              protected alternativeLabelService: AlternativeLabelService,
              protected windowService: HostWindowService,
              protected statletCountryCodeDataTypeService: StatletCountryCodeDataTypeService,
              @Inject(STATLET_DATA_TYPE_SERVICE_FACTORY) protected getStatletDataTypeServiceFor: (category: string, type: string) => GenericConstructor<any>) {
    super(parentInjector, colorService, alternativeLabelService, windowService, getStatletDataTypeServiceFor);
  }

  ngOnInit() {
    super.ngOnInit();
    // Create a unique ID for this statlet and route
    // We want to include the route url to avoid the same statlet on different pages clashing with each other
    this.id = this.statlet.id + '-world-heat-map-' + this.router.url;
    const colorSet = this.colorService.getColorSet(1, this.statlet.style.palette ? this.statlet.style.palette : this.palette);
    this.minColour = 'rgb(255,255,255)';
    this.maxColour = colorSet[0];
  }

  loadData() {
    this.data$ = observableOf(this.statlet.points.map((point) => {
      let value = 0;
      Object.values(point.values).forEach((v) => value += v);
      return new HeatMapEntry(point.label, value);
    }));

    this.labels$ = this.data$.pipe(
      switchMap((data) => combineLatest(
        ...data.map((entry) => this.statletCountryCodeDataTypeService.transform(entry.id).pipe(
          map((label) => Object.assign({ id: entry.id, label }))
        ))
      )),
      map((entries) => {
        const result = {};
        entries.forEach((entry) => {
          result[entry.id] = entry.label;
        });
        return result;
      })
    );
  }

}
